/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #333;
    color: #fff;
  }
  
  .navbar ul {
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  
  .navbar a {
    color: #fff;
    text-decoration: none;
  }
  