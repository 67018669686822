/* src/pages/Projects.css */

.projects {
    padding: 2rem;
    text-align: left;
  }
  
  .projects h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .project {
    margin-bottom: 2rem;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .project h3 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .project p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
  }
  